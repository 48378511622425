export const materialTypesData = [
	{
		id: 1,
		name: "Ст3",
		params: [
			{
				id: 101,
				thickness: 0.5,
				cutPrice: 16.5,
				punchesPrice: 0.41,
				materialName: "Ст3",
				materialId: 1,
			},
			{
				id: 102,
				thickness: 0.8,
				cutPrice: 16.5,
				punchesPrice: 0.41,
				materialName: "Ст3",
				materialId: 1,
			},
			{
				id: 103,
				thickness: 1,
				cutPrice: 16.5,
				punchesPrice: 0.41,
				materialName: "Ст3",
				materialId: 1,
			},
			{
				id: 104,
				thickness: 1.5,
				cutPrice: 19.5,
				punchesPrice: 0.62,
				materialName: "Ст3",
				materialId: 1,
			},
			{
				id: 105,
				thickness: 2,
				cutPrice: 22.16,
				punchesPrice: 1.1,
				materialName: "Ст3",
				materialId: 1,
			},
			{
				id: 106,
				thickness: 2.5,
				cutPrice: 23.14,
				punchesPrice: 1.1,
				materialName: "Ст3",
				materialId: 1,
			},
			{
				id: 107,
				thickness: 3,
				cutPrice: 28.04,
				punchesPrice: 1.43,
				materialName: "Ст3",
				materialId: 1,
			},
			{
				id: 108,
				thickness: 4,
				cutPrice: 31.25,
				punchesPrice: 1.43,
				materialName: "Ст3",
				materialId: 1,
			},
			{
				id: 109,
				thickness: 5,
				cutPrice: 34.13,
				punchesPrice: 1.43,
				materialName: "Ст3",
				materialId: 1,
			},
			{
				id: 110,
				thickness: 6,
				cutPrice: 36.58,
				punchesPrice: 1.74,
				materialName: "Ст3",
				materialId: 1,
			},
			{
				id: 111,
				thickness: 8,
				cutPrice: 47.6,
				punchesPrice: 2.69,
				materialName: "Ст3",
				materialId: 1,
			},
			{
				id: 112,
				thickness: 10,
				cutPrice: 58.52,
				punchesPrice: 2.36,
				materialName: "Ст3",
				materialId: 1,
			},
			{
				id: 113,
				thickness: 12,
				cutPrice: 75.59,
				punchesPrice: 3.3,
				materialName: "Ст3",
				materialId: 1,
			},
			{
				id: 114,
				thickness: 14,
				cutPrice: 86.45,
				punchesPrice: 4.37,
				materialName: "Ст3",
				materialId: 1,
			},
			{
				id: 115,
				thickness: 15,
				cutPrice: 96.91,
				punchesPrice: 4.87,
				materialName: "Ст3",
				materialId: 1,
			},
			{
				id: 116,
				thickness: 16,
				cutPrice: 109.72,
				punchesPrice: 5.57,
				materialName: "Ст3",
				materialId: 1,
			},
		],
	},
	{
		id: 2,
		name: "AISI",
		params: [
			{
				id: 201,
				thickness: 0.8,
				cutPrice: 26.66,
				punchesPrice: 0.66,
				materialName: "AISI",
				materialId: 2,
			},
			{
				id: 202,
				thickness: 1,
				cutPrice: 26.66,
				punchesPrice: 0.66,
				materialName: "AISI",
				materialId: 2,
			},
			{
				id: 203,
				thickness: 1.5,
				cutPrice: 27.43,
				punchesPrice: 0.66,
				materialName: "AISI",
				materialId: 2,
			},
			{
				id: 204,
				thickness: 2,
				cutPrice: 35.46,
				punchesPrice: 1.75,
				materialName: "AISI",
				materialId: 2,
			},
			{
				id: 205,
				thickness: 2.5,
				cutPrice: 66.39,
				punchesPrice: 3.05,
				materialName: "AISI",
				materialId: 2,
			},
			{
				id: 206,
				thickness: 3,
				cutPrice: 71.25,
				punchesPrice: 3.05,
				materialName: "AISI",
				materialId: 2,
			},
			{
				id: 207,
				thickness: 4,
				cutPrice: 74.44,
				punchesPrice: 3.4,
				materialName: "AISI",
				materialId: 2,
			},
			{
				id: 208,
				thickness: 5,
				cutPrice: 80.21,
				punchesPrice: 3.4,
				materialName: "AISI",
				materialId: 2,
			},
			{
				id: 209,
				thickness: 6,
				cutPrice: 84.76,
				punchesPrice: 4.03,
				materialName: "AISI",
				materialId: 2,
			},
			{
				id: 210,
				thickness: 8,
				cutPrice: 103.91,
				punchesPrice: 5.91,
				materialName: "AISI",
				materialId: 2,
			},
			{
				id: 211,
				thickness: 10,
				cutPrice: 123.06,
				punchesPrice: 7.3,
				materialName: "AISI",
				materialId: 2,
			},
		],
	},
	{
		id: 3,
		name: "Оц",
		params: [
			{
				id: 301,
				thickness: 0.8,
				cutPrice: 26.66,
				punchesPrice: 0.66,
				materialName: "Оц",
				materialId: 3,
			},
			{
				id: 302,
				thickness: 1,
				cutPrice: 26.66,
				punchesPrice: 0.66,
				materialName: "Оц",
				materialId: 3,
			},
			{
				id: 303,
				thickness: 1.5,
				cutPrice: 27.43,
				punchesPrice: 0.66,
				materialName: "Оц",
				materialId: 3,
			},
			{
				id: 304,
				thickness: 2,
				cutPrice: 35.46,
				punchesPrice: 1.75,
				materialName: "Оц",
				materialId: 3,
			},
			{
				id: 305,
				thickness: 2.5,
				cutPrice: 66.39,
				punchesPrice: 3.05,
				materialName: "Оц",
				materialId: 3,
			},
			{
				id: 306,
				thickness: 3,
				cutPrice: 71.25,
				punchesPrice: 3.05,
				materialName: "Оц",
				materialId: 3,
			},
			{
				id: 307,
				thickness: 4,
				cutPrice: 74.44,
				punchesPrice: 3.4,
				materialName: "Оц",
				materialId: 3,
			},
			{
				id: 308,
				thickness: 5,
				cutPrice: 80.21,
				punchesPrice: 3.4,
				materialName: "Оц",
				materialId: 3,
			},
			{
				id: 309,
				thickness: 6,
				cutPrice: 84.76,
				punchesPrice: 4.03,
				materialName: "Оц",
				materialId: 3,
			},
			{
				id: 310,
				thickness: 8,
				cutPrice: 103.91,
				punchesPrice: 5.91,
				materialName: "Оц",
				materialId: 3,
			},
			{
				id: 311,
				thickness: 10,
				cutPrice: 123.06,
				punchesPrice: 7.3,
				materialName: "Оц",
				materialId: 3,
			},
		],
	},
	{
		id: 4,
		name: "Нерж",
		params: [
			{
				id: 401,
				thickness: 0.8,
				cutPrice: 26.66,
				punchesPrice: 0.66,
				materialName: "Нерж",
				materialId: 4,
			},
			{
				id: 402,
				thickness: 1,
				cutPrice: 26.66,
				punchesPrice: 0.66,
				materialName: "Нерж",
				materialId: 4,
			},
			{
				id: 403,
				thickness: 1.5,
				cutPrice: 27.43,
				punchesPrice: 0.66,
				materialName: "Нерж",
				materialId: 4,
			},
			{
				id: 404,
				thickness: 2,
				cutPrice: 35.46,
				punchesPrice: 1.75,
				materialName: "Нерж",
				materialId: 4,
			},
			{
				id: 405,
				thickness: 2.5,
				cutPrice: 66.39,
				punchesPrice: 3.05,
				materialName: "Нерж",
				materialId: 4,
			},
			{
				id: 406,
				thickness: 3,
				cutPrice: 71.25,
				punchesPrice: 3.05,
				materialName: "Нерж",
				materialId: 4,
			},
			{
				id: 407,
				thickness: 4,
				cutPrice: 74.44,
				punchesPrice: 3.4,
				materialName: "Нерж",
				materialId: 4,
			},
			{
				id: 408,
				thickness: 5,
				cutPrice: 80.21,
				punchesPrice: 3.4,
				materialName: "Нерж",
				materialId: 4,
			},
			{
				id: 409,
				thickness: 6,
				cutPrice: 84.76,
				punchesPrice: 4.03,
				materialName: "Нерж",
				materialId: 4,
			},
			{
				id: 410,
				thickness: 8,
				cutPrice: 103.91,
				punchesPrice: 5.91,
				materialName: "Нерж",
				materialId: 4,
			},
			{
				id: 411,
				thickness: 10,
				cutPrice: 123.06,
				punchesPrice: 7.3,
				materialName: "Нерж",
				materialId: 4,
			},
		],
	},
];

export const materialsData = [
	...materialTypesData[0].params,
	...materialTypesData[1].params,
	...materialTypesData[2].params,
];
