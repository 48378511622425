import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Button, Checkbox, Form, Input, message } from "antd";
import { pick, omit } from "lodash";

import { InputMask } from "components/InputMask/InputMask";
import { useCheckoutFormContext } from "context/useCheckoutFormContext";
import { useItemsContext } from "context/useItemsContext";
import { useLoadingContext } from "context/useLoadingContext";
import { useUploadFilesContext } from "context/useUploadFilesContext";
import { Storage, Routes } from "types";
import { getExcelFile } from "utils/getExcelFile";

import { materialsData, materialTypesData } from "../../constants";
import { generateRandomDigitNumber } from "../../utils";

import styles from "./CheckoutForm.module.scss";
import checkoutFormService from "./checkoutFormService";

const formItemLayout = {
	labelCol: {
		span: 4,
	},
	wrapperCol: {
		span: 8,
	},
};

const formTailLayout = {
	labelCol: {
		span: 4,
	},
	wrapperCol: {
		span: 8,
		offset: 4,
	},
};

const AllowedFields = [
	"name",
	"cutLength",
	"punches",
	"widthX",
	"heightY",
	"weight",
	"materialType",
	"materialThickness",
	"quantity",
	"price",
];

export const CheckoutForm = () => {
	const [form] = Form.useForm();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [submittable, setSubmittable] = React.useState(false);
	const { uploadFiles, setInitUploadFiles } = useUploadFilesContext();
	const { checkoutForm, setCheckoutForm } = useCheckoutFormContext();
	const { loading, setLoading } = useLoadingContext();
	const { items, setItems } = useItemsContext();

	const initialValues = {
		comment: "",
		agreement: false,
	};

	const userValues = Form.useWatch([], form);

	const handleChangeCheckbox = (event: any) => {
		const { checked } = event.target;
		setSubmittable(checked);
	};

	const handleSaveFormData = () => {
		setCheckoutForm(userValues);
	};

	const onSubmit = async () => {
		localStorage.setItem(Storage.CheckoutForm, userValues);
		setLoading(true);
		try {
			// const validValues = await form.validateFields();
			const formData = new FormData();

			const userValuesData = {
				...omit(userValues, ["agreement"]),
				orderId: await generateRandomDigitNumber(),
			};
			Object.keys(userValuesData).forEach((key) => {
				formData.append(key, userValuesData?.[key]);
			});

			// Parse data before send
			const itemsData = items?.map((i) => {
				return {
					...pick(i, AllowedFields),
					materialType: materialsData.find((f) => f.materialId === i.materialType)?.materialName,
					materialThickness: materialsData.find((f) => f.id === i.materialThickness)?.thickness,
				};
			});

			const itemIds = items?.map((i) => i.uid);
			const files = uploadFiles
				?.map((file) => file.originFileObj)
				?.filter((el) => itemIds.includes(el.uid));

			// Write data to formData
			if (files?.length) {
				files?.forEach((item) => {
					formData.append("files[]", item);
				});
			}

			const excelFile = itemsData?.length && (await getExcelFile(itemsData));

			excelFile &&
				formData.append(
					"excelFile",
					excelFile,
					`Зам._${userValuesData?.orderId}_${userValues?.name}.xlsx`,
				);

			if (itemsData?.length && userValuesData) {
				const res = await checkoutFormService.sendCheckoutForm(formData);

				if (res?.status === 200) {
					message.success(t("messageOrderSent"));
					console.info("Success:", userValuesData, { res });

					setInitUploadFiles([]);
					setItems([]);
					setLoading(() => false);
					navigate(Routes.ThanksPage);
				} else {
					message.error(t("messageOrderSentTryLatter"));
					setLoading(() => false);
				}
			}
		} catch (errorInfo) {
			message.error(t("messageError"));
			console.info("Failed:", errorInfo);
			setLoading(false);
		}
	};

	useEffect(() => {
		form.validateFields({ validateOnly: true }).then(
			() => {
				if (userValues?.agreement) setSubmittable(true);
			},
			() => {
				setSubmittable(false);
			},
		);
	}, [userValues]);

	return (
		<div className={styles.formWrapper}>
			<h3>{t("checkout")}</h3>
			<Form
				form={form}
				onFinish={onSubmit}
				onBlur={handleSaveFormData}
				initialValues={initialValues}
				name='candle-form'
				validateTrigger='onChange'
				autoComplete='off'
			>
				<Form.Item
					{...formItemLayout}
					className={styles.formItem}
					name='name'
					label={t("labelName")}
					rules={[
						{
							required: true,
							message: t("name"),
						},
					]}
				>
					<Input className={styles.inputWrapper} placeholder={t("namePlace")} />
				</Form.Item>
				<Form.Item
					{...formItemLayout}
					className={styles.formItem}
					name='phone'
					label={t("labelPhone")}
					rules={[
						{
							required: true,
							message: t("messageWrongPhone"),
						},
					]}
				>
					<InputMask
						mask={"+38(099)-999-99-99"}
						maskChar={null}
						formatChars={{ "9": "[0-9]" }}
						placeholder={t("phoneNumber")}
					/>
				</Form.Item>

				<Form.Item
					{...formItemLayout}
					className={styles.formItem}
					name='email'
					label='Email'
					rules={[
						{
							type: "email",
							required: true,
							message: t("massageWrongEmail"),
						},
					]}
				>
					<Input className={styles.inputWrapper} placeholder={t("email")} />
				</Form.Item>

				<Form.Item
					{...formItemLayout}
					className={styles.formItem}
					name='comment'
					label={t("labelComment")}
				>
					<Input.TextArea className={styles.inputWrapper} showCount maxLength={300} />
				</Form.Item>

				<Form.Item {...formTailLayout} name='agreement' className={styles.formItem}>
					<Checkbox onChange={(e) => handleChangeCheckbox(e)}>
						{t("termsAgreeing")}
						<a href='https://candle.ua/en/privacy-policy/' target='_blank' rel='noreferrer'>
							{t("privacyPolicy")}
						</a>
					</Checkbox>
				</Form.Item>
				<Form.Item {...formTailLayout} className={styles.formItem}>
					<Button type='primary' htmlType='submit' disabled={!submittable || loading}>
						{t("sendOrder")}
					</Button>
				</Form.Item>
			</Form>
		</div>
	);
};
