import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { createRoot } from "react-dom/client";
import { ThemeProvider } from "styled-components";

import { ProviderCheckoutForm } from "context/useCheckoutFormContext";
import { ProvideItems } from "context/useItemsContext";
import { ProvideUploadFiles } from "context/useUploadFilesContext";
import { ThanksPage } from "pages/ThanksPage";
import { GlobalStyles, theme } from "theme";

import Layout from "./components/Layout";
import { ProvideLoading } from "./context/useLoadingContext";
import ErrorPage from "./pages/ErrorPage";
import { HomePage } from "./pages/HomePage";
import { Routes } from "./types";

import "./utils/i18n";

const container = document.getElementById("root")!;
const root = createRoot(container);

const router = createBrowserRouter([
	{
		path: Routes.Home,
		element: (
			<Layout>
				<HomePage />
			</Layout>
		),
		errorElement: <ErrorPage />,
	},
	{
		path: Routes.ThanksPage,
		element: (
			<Layout>
				<ThanksPage />
			</Layout>
		),
		errorElement: <ErrorPage />,
	},
]);

root.render(
	<>
		<ThemeProvider theme={theme}>
			<GlobalStyles />
			<ProvideUploadFiles>
				<ProvideItems>
					<ProvideLoading>
						<ProviderCheckoutForm>
							<RouterProvider router={router} />
						</ProviderCheckoutForm>
					</ProvideLoading>
				</ProvideItems>
			</ProvideUploadFiles>
		</ThemeProvider>
	</>,
);
